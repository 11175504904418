<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h4 style="text-align: center">Preços dos Produtos dos Clientes</h4>
    <div class="row" id="search">
      <div class="col-md-4">
        <v-select
          :options="clients"
          v-model="clientSearch"
          label="client"
          @input="getAllProducts()"
        >
        </v-select>
      </div>
      <div class="col-md-4">
        <b-form-input
          v-model="skuSearch"
          placeholder="Search by SKU/CNP"
          class="mb-3"
          @change="getAllProducts()"
        ></b-form-input>
      </div>
      <div class="col-md-4">
        <select
          name="status"
          id=""
          class="form-select"
          v-model="statusSearch"
          @change="getAllProducts()"
        >
          <option value="all" selected>All</option>
          <option value="true">Active</option>
          <option value="false">Disable</option>
        </select>
      </div>
    </div>
    <div>
      <b-table :items="products" :fields="fields" class="table">
        <template #cell(active)="data">
          <input
            type="checkbox"
            v-model="data.item.active"
            @change="updateData(data.item, false)"
            :id="data.id"
          />
          <label :for="data.id" v-if="data.item.active == true">Active</label>
          <label :for="data.id" v-else>Disable</label>
        </template>
        <template #cell(price)="data">
          <span>{{ data.item.price }} {{ data.item.currency }}</span>
        </template>
        <template #cell(price_eur)="data">
          <input
            class="form-control"
            type="number"
            v-model="data.item.price_eur"
            @change="updatePriceEur(data.item, true)"
          />
        </template>
        <template #cell(button)="data">
          <button
            class="btn btn-danger btn-sm"
            @click="removeProductPrice(data.item.id)"
          >
            Remove
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      products: [],
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "sku",
          label: "SKU/CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "client",
          label: "Client",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "price_eur",
          label: "Price EUR",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle; width:150px",
          sortable: true,
        },
        {
          key: "active",
          label: "Status",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "button",
          label: "Remove",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
      ],
      skuSearch: "",
      statusSearch: "all",
      clients: [],
      clientSearch: "",
    };
  },
  methods: {
    async getAllProducts() {
      this.loading = true;
      console.log(this.clientSearch);
      if (this.clientSearch == null) {
        this.clientSearch = "";
      }
      try {
        await this.$store.dispatch("allProductsClientPrice", {
          client: this.clientSearch.client,
          sku: this.skuSearch,
          active: this.statusSearch,
        });
        this.products = this.getallProductsClientPrice;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async listAllClients() {
      try {
        await this.$store.dispatch("allClientsInProductsPrice");
        this.clients = this.getListProductsClientPrice;
      } catch (err) {
        alert(err);
      }
    },
    async updatePriceEur(data, updatePrice) {
      if (updatePrice == true) {
        this.$swal({
          title: `Quer atualizar o preço do produto para ${data.price_eur}?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.updateData(data, updatePrice);
          }
        });
      }
    },
    async updateData(data, updatePrice) {
      console.log(data);
      try {
        await this.$store.dispatch("updateClientsProductsPrice", {
          id: data.id,
          price_eur: data.price_eur,
          active: data.active,
          updatePrice: updatePrice,
        });
        this.$swal({
          toast: true,
          position: "top-end",
          title: "Sucesso!",
          text: "Dados atualizados com sucesso!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    async removeProductPrice(id) {
      this.$swal({
        title: "Quer remover o produto?",
        text: "Não será possível reverter essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("removeClientProductPrice", { id: id });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Produto removido com sucesso!",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getAllProducts();
          } catch (err) {
            alert(err);
          }
        }
      });
    },
  },
  created() {
    this.getAllProducts();
    this.listAllClients();
  },
  computed: {
    ...mapGetters(["getallProductsClientPrice"]),
    ...mapGetters(["getListProductsClientPrice"]),
  },
};
</script>

<style>
h4 {
  margin: 20px 0px 20px 0px;
}
#search {
  margin: 0px 50px 0px 50px;
}
.table {
  text-align: center;
}
</style>
